//React

import React from "react";

function Kabala() {

  React.useEffect(() => {
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: "/kabala",
        title: "Kabala"
      }
    });

    document.title = process.env.REACT_APP_NAME + ' - Kabala';
  }, []);

  return (
    <section id="kabala" className="w-full max-w-viewport pl-6 pr-6 2xl:pr-0 2xl:pl-0">
      <h2 className="font-medium text-center text-20 2xl:text-40 mb-10">Kabala</h2>
      <div className="flex flex-col gap-2">


        <h3 className="text-center text-17 font-medium">
          A „Keresd meg a Rajt Kabala autót nyereményjáték” c. Nyereményjáték Szabályzata
        </h3>
        <p className=" font-bold">
          1. A Játék szervezője
        </p>
        <p>
        </p>
        A Rajt Autósiskola Intagram/Facebook/TikTok profilján szervezett nyereményjáték (a továbbiakban:
        Játék vagy Játékok) szervezője a Rajt Autósiskola iskolavezetője, Czutor Emil (adatok: Czutor Emil E.V.,
        Székhelye: 2600 Vác, Sas u. 1. 2. emelet 2. ajtó, Nyilvántartási szám: 54262375, Adószám:
        55562324-1-51) (a továbbiakban: Szervező). A Játék lebonyolításával összefüggő, azok végrehajtásával
        kapcsolatos egyes feladatokat a Szervező látja el.
        <p className=" font-bold">
          2. A játékban való részvétel feltétele
        </p>
        <p>
          2.1 A Játékban kizárólag magyarországi lakó- vagy tartózkodási hellyel, valamint a magyar hatóság
          által kiállított érvényes személyazonosító igazolvánnyal rendelkező, 18. életévét betöltött magyar
          állampolgár vehet részt, aki nem esik a 2.7 pontban meghatározott személyi körbe,
          cselekvőképessége nem korlátozott (a továbbiakban: Játékos) továbbá, aki
          a Szervező által közzétett Posztban meghirdetett feladatokat az ott írtak szerint teljesíti, azaz megírja
          a helyes választ a posztban feltett kérdésre,
          továbbá hozzájárul ahhoz, hogy Szervező a Játék során a Játékos által rendelkezésre bocsátott
          személyes adatait) Játékszabályzatban rögzítettek szerint az adott Játékkal összefüggésben kezelje, és
          a részvétellel a jelen szabályzatban (a továbbiakban Játékszabályzat) írt valamennyi feltételt
          elfogadja.
        </p>
        <p>
          2.2 A Pályázat
          A poszthoz a 2.1 pontban meghatározottakon kívül nem szükséges egyéb kommentet írni.
          A kommentben írtak
          nem sérthetik más szerzői és személyiségi jogait,
          nem szíthat faji, nemzetiségi, vallási, világnézeti, nemi vagy politikai ellentéteket, illetve nem
          tartalmazhat faji, nemzetiségi, vallási, világnézeti, nemi vagy politikai hovatartozás miatti
          diszkriminatív kijelentést vagy utalást sem nyílt, sem burkolt formában,
          erőszakos és egyéb, a gyermekek fejlődését hátrányosan befolyásoló jeleneteket, kijelentéseket nem
          tartalmazhat,
          nem lehet pornográf, trágár, alpári, másokat lejárató tartalmú,
          nem tartalmazhat a Rajt Autósiskolára, valamint az általa forgalmazott termékekre vonatkozó negatív
          tartalmú kijelentéseket, képbeállítást,
          nem lehet a Játék témájával vagy címével összefüggésben nem álló,
          nem tartalmazhat bűncselekményre vagy szabálysértésre, illetve más jogszabálysértésre való
          felbujtást,
          nem tartalmazhat jogosulatlan vagy tisztességtelen piaci magatartás és versenykorlátozás tilalmáról
          szóló törvény által tiltott módon megszerzett adatokat vagy üzleti titkokat, szabadalmakat és
          védjegyeket, adatvédelmi jogszabályokba ütköző közléseket,
          nem sértheti a természeti, történelmi, tudományos, kulturális értékek megbecsülését, illetve nem
          ütközhet egyéb jogszabály rendelkezéseibe, nem sértheti az általánosan elfogadott erkölcsi normákat,
          valamint a jó ízlést.
          Azt a Játékost, akinek Pályázata nem felel meg a fenti követelményeknek, továbbá bebizonyosodik,
          hogy más szerző szerzői jogát megsérti, a Szervezők kizárják a játékból.
        </p>
        <p>
          2.3 Egy Játékos a Játékban egy komment írásával jogosult részt venni. Több komment esetén is a
          Játékos kizárólag egy kommenttel nyerhet.
        </p>
        <p>
          2.4 Azok a Pályázatok, amelyek a jelen Játékszabályzatban és az adott Posztban leírt alaki és tartalmi
          előírásoknak nem felelnek meg, az adott Játékból automatikusan kizárásra, míg az érvényes
          Pályázatok számítógépes rögzítésre kerülnek. Szervező a valótlan adatokat megadó Játékosokat
          kizárhatja az adott Játékból.
        </p>
        <p>
          2.5 Szervező kizárólag azokat a Pályázatokat tekinti érvényesnek, amelyeket a Játékosok saját maguk
          által regisztrált profillal küldenek be az adott Játékba. Amennyiben a Játékos nem saját maga által
          regisztrált profilt, illetve nem saját e-mail címet vesz igénybe, a Szervező kizárja a Játékkal, illetőleg a
          nyereménnyel kapcsolatos, profil és e-mail fiók használat jogosultságából fakadó vitákkal kapcsolatos
          mindennemű felelősségét. Szervező a nyeremények átadásakor ellenőrzi Játékos és a nyertes
          profilban meghatározott adatok azonosságát, és amennyiben nem egyeznek, úgy megtagadhatja a
          nyeremény átadását. A Szervező az ellenőrzés körében jogosult az adatok azonossága körében
          nyilatkozatot kérni a nyertestől.
        </p>
        <p>
          2.6 A Játékos tudomásul veszi, hogy a Játék technikai infrastruktúrájának tartalma, teljesítménye,
          üzenet- és adatátviteli-, valamint válaszadási sebessége a kiszolgáló technológia függvénye, és ezáltal
          ezeket kedvezőtlenül befolyásolhatja olyan, a Szervezőn kívülálló tényező, mint például (de nem
          kizárólagosan) kapcsolati hiba, a szerver számítógépek teljesítménye, a hálózati leterheltség, a
          hálózati torlódás, a lefedettség, valamint a biztonságos hálózati kapcsolat fenntartása. A Szervező
          kizárja a mindennemű felelősségét a jelen pontban meghatározottakból eredő hibáért, késedelemért
          vagy bármilyen ilyen jellegű körülményért.
        </p>
        <p>
          2.7 Játékokból ki vannak zárva a Szervező, a Játék szervezésében részt vevő egyéb harmadik
          személyek, továbbá ezek alkalmazottjai és megbízottjai és ezen személyek a Polgári Törvénykönyvről
          szóló 2013. évi V. törvény 8:1. § (1) bekezdésének 1. pontjában meghatározott közeli hozzátartozói.
        </p>
        <p>
          2.8 Az adott Játékból – a Szervező megítélése alapján – kizárásra kerülhetnek azok a Játékosok, akik
          egy természetes személy neve alatt csapatban, nem valós Instagram-profillal vesznek részt, azzal a
          céllal, hogy a nyerési esélyeiket ezzel a megtévesztő magatartással megnöveljék.
        </p>
        <p>
          2.9. A nyertes sorsolás útján kerül kiválasztásra.
        </p>
        <p className=" font-bold">
          3. A nyereményjáték időtartama
        </p>
        3.1 A játék 2024. május 10., 12:00 órától 2024. május 10., 18:00 óráig tart.
        <p className=" font-bold">
          4. Nyeremény
        </p>
        <p>
          4.1 Szervező a 2.1 pontban meghatározott Játékhoz kapcsolódóan 1 (egy) nyertest és 1 (egy)
          tartaléknyertest sorsol ki. A Játék nyereménye:
          egy darab Rajt ajándékutalvány, melyet a nyertes gyakorlati oktatásra válthat be,
          a nyeremény értéke: 50.000, - forint.
        </p>
        <p>
          4.2 A sorsolás eredményét legkésőbb 2024. május 15., 12:00 óráig teszi közzé Szervező a Rajt
          Autósiskola weboldalán.
        </p>
        <p>
          4.3 Szervező az adott Játék nyereményét mindig a Játék meghirdetésével egyidejűleg, az adott
          Posztban és jelen játékszabályzatban teszi közzé (a továbbiakban: Nyeremény).
        </p>
        <p>
          4.4 A Nyeremény másra át nem ruházható, és készpénzre nem váltható. Egy Játékos egy adott Játék
          teljes időtartama alatt kizárólag 1 darab nyereményre lehet jogosult, a Pályázatok számától
          függetlenül.
        </p>
        <p>
          4.5 Szervező a Nyeremény nyertesét legkésőbb a sorsolást, illetve a nyertes meghatározását követő 3
          (három) munkanapon belül értesíti posztban (a nyertes profilneve kerül megjelenítésre), amelyhez
          valamennyi Játékos az adott Játékban a Pályázat benyújtásával kifejezetten hozzájárul (a
          továbbiakban: Értesítés). A nyertes Játékos a profilnak küldött privát üzenetben köteles az Értesítés
          kézhezvételét követő 8 (nyolc) munkanapon belül visszaigazolni az Értesítést, valamint a Nyeremény
          kézbesítéséhez szükséges személyes adatait (teljes név, magyarországi lakcím) pontosan megadni.
          Amennyiben a nyertes a fenti határidőn belül az Értesítést nem igazolja vissza, vagy a Nyeremény
          kézbesítéséhez szükséges adatait nem adja meg, úgy a nyertes Játékos Pályázata kizárásra kerül, és
          Szervező a kijelölt tartaléknyertest értesíti a nyereményről. A tartaléknyertes visszaigazolási
          kötelezettségére a nyertes visszaigazolási kötelezettségére vonatkozó szabályok értelemszerűen
          alkalmazandóak, amennyiben a tartaléknyertes az Értesítést a jogvesztő nyolc munkanapos jogvesztő
          határidőn belül nem igazolja vissza, úgy a nyeremény nem kerül átadásra.
        </p>
        <p>
          4.6 A Nyereményt a nyertes a Rajt Autósiskola irodájában (2600 Vác, Görgey Artúr utca 1.) veheti át
          személyesen, nyitvatartási időn belül. Szervező a Nyeremény átadását kétszer kísérli meg, a nyertes
          meghatározásától számított 60 naptári napon belül. A nyertes Játékos köteles együttműködni annak
          érdekében, hogy a Nyeremény átvételére a postai szolgáltatásokról szóló 2012. évi CLIX. törvényben
          foglaltak szerint sor kerüljön. Ha ezen együttműködési kötelezettségének a nyertes nem tesz eleget,
          és így a nyeremény átadása meghiúsul, úgy ezen körülmény a Szervező terhére nem értékelhető. A
          Szervező a Nyeremény átvételére újabb lehetőséget nem biztosít.
        </p>
        <p className=" font-bold">
          5. Adózás, költségek
        </p>
        <p>
          5.1 A Nyereményhez tartozó esetleges SZJA fizetési kötelezettséget a Szervező viseli. Szervezőt a
          Nyeremény fentiek szerinti átadásán és adóvonzatuk kiegyenlítésén kívül további kötelezettség nem
          terheli. A Nyeremények kézbesítésével járó futárköltséget a Szervező viseli. Egyéb esetlegesen
          felmerülő költségek a Játékost terhelik.
        </p>
        <p className=" font-bold">
          6. Adatvédelmi rendelkezések
        </p>
        <p>
          6.1 Az adatkezelés célja és időtartama
          Az adatkezelés célja, hogy a Szervező a Játékos nyereményjátékban történő részvételét biztosíthassa,
          a nyereményjáték lebonyolításával, valamint a Nyeremények átadásával kapcsolatban a Játékosok
          személyes adatait kezelje.
          A Szervező a nyertes Játékos által megadott személyes adatokat (név, lakcím, e-mail cím, telefonszám)
          a Játékkal összefüggésben, a Játék lebonyolítási időtartama alatt a kapcsolattartás, valamint a
          nyeremények átadása céljából kezelik. A Szervező a nem nyertes Játékosok személyi adatait nem
          kezeli, kivéve a Játékos Instagramon megadott felhasználónevét abban az időtartamban, amíg a
          Játékos a Instagram-profilon regisztrált. A regisztráció törlését követően az adatkezelés megszűnik. A
          Szervező az általa kezelt adatokat harmadik személynek nem adja át, a Szervező fenntartja a jogot,
          hogy felhasználók részére a közösségi portálon marketinganyagokat osszon meg az Meta általános
          szabályai szerint.
        </p>
        <p>
          6.2 A személyes adatok kezelésének jogalapja
          A Játékban történő részvétellel kapcsolatos adatszolgáltatás önkéntes. Az információs önrendelkezési
          jogról és az információ szabadságról szóló 2011. évi CXII törvény (továbbiakban: Infotv.) 5.§ (1)
          bekezdés b) pontja értelmében a Játékos a Játékban történő részvétellel, azaz a Pályázat
          benyújtásával kifejezetten hozzájárulását adja ahhoz, hogy személyes adatait Szervező a
          Játékszabályzatban meghatározottak szerint kezelje, illetve használja fel, továbbá hozzájárul ahhoz,
          hogy nyertessége esetén nevét a Szervező minden további feltétel és ellenérték megfizetése nélkül,
          kizárólag az adott Játékkal kapcsolatosan a közösségi profilon nyilvánosságra hozza, a nyilvánosságra
          hozatal kizárólag a Játékos nevét és nyerésének tényét, továbbá a nyeremény tárgyát tartalmazza.
        </p>
        <p>
          6.3 A Játékos adatvédelemmel kapcsolatos kötelezettségei
          A Játékos kizárólag saját valós adatait adhatja meg. A Játékos az adatok megadásával kifejezetten
          szavatolja, hogy saját és valós adatait adta meg. Amennyiben más személy adatainak megadásával
          más személy jogát sérti, úgy köteles az ebből esetlegesen eredő a Szervezőnél keletkezett kár
          összegét a polgári jog szabályai szerint megtéríteni, melybe beleértendő a Szervező által harmadik
          személynek fizetendő kártérítés, vagy hatóság által megállapított bírság is.
        </p>
        <p>
          6.4 Adatkezelő
          A Játék során a Játékos által megadott adatok kezelője a Rajt Autósiskola iskolvezetője, Czutor Emil,
          mint adatkezelő. A Szervező elérhetőségei: 2600 Vác, Görgey Artúr utca 1., +36 70 4020 112.
        </p>
        <p>
          6.5 Játékos jogai személyes adatai kezelésével kapcsolatban
          A Játékos jogosult kérni a személyes adatai helyesbítését (megjelölve a helyes adatokat). Az
          adatkezelő a helyesbítést haladéktalanul elvégzi a nyilvántartásában, és ennek megtörténtéről írásban
          értesítik a Játékost.
          A Játékos bármikor kérheti az adatai törlését – részben vagy egészben – ingyenesen, indokolás nélkül.
          Az adatkezelő a törlési kérelem kézhezvételét követően haladéktalanul gondoskodik a Játékos
          adatainak kezelése megszüntetéséről, és törli a Játékost és a Játékos adatait a nyilvántartásából.
          Az Adatkezelő a tájékoztatás, helyesbítés vagy törlés iránti kérelmeket az alábbi elérhetőségeken
          fogadja: Rajt Autósiskola, info@rajtautosiskola.hu
        </p>
        <p>
          6.6. Adatbiztonság
          A Szervező kötelezi magát arra, hogy gondoskodik az adatok biztonságáról, megteszi továbbá azokat a
          technikai és szervezési intézkedéseket, és kialakítja azokat az eljárási szabályokat, amelyek biztosítják,
          hogy a felvett, tárolt, illetve kezelt adatok védettek legyenek, illetőleg megakadályozza jogosulatlan
          felhasználását és jogosulatlan megváltoztatását. A Szervező gondoskodik arról, hogy a kezelt
          adatokhoz illetéktelen személy ne férhessen hozzá, ne hozhassa nyilvánosságra, ne továbbíthassa,
          valamint azokat ne módosíthassa, törölhesse.
        </p>
        <p className=" font-bold">
          7. Vegyes rendelkezések
        </p>
        <p>
          7.1. A pályázatok hiányosságáért/hibájáért (pl. névelírás, címelírás, téves vagy nem valós adat
          feltüntetése stb.), a nyeremények kézbesítésének – Szervező érdekkörén kívül eső – elmaradásáért
          vagy késedelméért, ill. a kézbesítés során keletkezett károkért Szervező kizárja a felelősségét.
        </p>
        <p>
          7.2. Amennyiben a nyertes a nyereményét határidőben nem veszi át, azt a továbbiakban a szervezőtől
          nem követelheti.
        </p>
        <p>
          7.3. A Nyereményért a Szervező a mindenkor hatályos jogszabályok szerinti jótállást és szavatosságot
          vállalja.
        </p>
        <p>
          7.4. Szervező kizárja a felelősségét minden, a
          www.instagram.com/www.facebook.com/www.tiktok.com weboldalt, a közösségi profilt, illetve az
          azokat működtető szervert ért külső, ún. sql támadások, illetve a telefonhálózatot ért támadások,
          meghibásodás estére. Tehát amennyiben a közösségi profilt, illetve szervert vagy a kiszolgáló
          hálózatot ért támadás folytán a Játékosok téves rendszerüzeneteket kapnak nyereményeiket, a
          nyertes/nem nyertes státuszukat stb. illetően, úgy ezen esetekre a Szervező semminemű felelősséget
          nem vállal.
        </p>
        <p>
          7.5. A Szervező fenntartja a jogot a Játékszabályzat módosítására. Amennyiben a Játékszabályzat
          módosítása jogszabályváltozás miatt válik szükségessé, úgy a Szervezőnek jogában áll a
          Játékszabályzat módosítása egy adott Játék időtartama alatt is, ebben az esetben a Játékszabályzat
          módosításának hatálya a Szervező döntésétől függően kiterjed a folyamatban lévő Játékra is.
        </p>
        <p>
          7.6. A Játékos a Pályázat 2.1. pont szerinti benyújtásával kijelenti, hogy a Játékszabályzatot elolvasta,
          megértette és a Játékszabályzat valamennyi rendelkezését magára nézve kötelezőnek ismeri el.
          7.7 A Játék semmilyen módon nem szponzorált az Instagram által, illetve semmilyen tekintetben nem
          kapcsolódik az Instagramhoz/Facebookhoz/TikTokhoz.
          <p className="font-medium mt-4">
            Budapest, 2024. május 10.
            Rajt Autósiskola
          </p>

        </p>
        <a href="/documents/Rajt_kabala_auto_nyeremenyjatek szabalyzat_majus.pdf" download className="flex flex-row gap-2 items-center hover:text-secondary text-black">
          <p className="text-12 2xl:text-17 font-medium">Letöltés</p>
        </a>
      </div>
    </section>
  );
}

export default Kabala;
