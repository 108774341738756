//React

import React from "react";

function PartnerCard() {

  React.useEffect(() => {
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: "/informaciok/partnerek",
        title: "Partnerprogram"
      }
    });

    document.title = process.env.REACT_APP_NAME + ' - Partnerprogram';
  }, []);

  return (
    <section id="partner-card" className="w-full max-w-viewport pl-6 pr-6 2xl:pr-0 2xl:pl-0">
      <div className="flex flex-col justify-center items-center lg:items-start lg:flex-row">
        <div>
          <img src="/assets/images/partnerkartya_mockup.png" alt="card" className="min-w-[19rem]" />
        </div>
        <div>
          <h3 className="font-extrabold text-15 lg:text-19 lg:mt-10">Partnerprogram</h3>
          <p className="font-normal text-13 lg:text-15">
            Tanulóink sikeres KRESZ vizsga után kiválthatják a Rajt autósiskola partnerprogram kártyát, amellyel a következő kedvezményeket tudják igénybe venni partnereinknél*:
          </p>
          <h4 className="text-12 lg:text-14 font-bold mt-16">
            Guttmann-Auto Kft.
          </h4>
          <p className="text-12 lg:text-14 font-normal">
            Gépjármű karbantartás és szerviz 20% kedvezmény (anyag és munkadíj)<br />
            Cascos fizetés esetén önrész kedvezmény 20% <br />
            Cím: Verőce, Magyarkúti út 21, 2621 <br />
            Telefonszám: (06 27) 350 670
          </p>
          <h4 className="text-12 lg:text-14 font-bold mt-6">
            Majoros Pláza Kft. Majoros Pláza Vác - Kulcsmásolás, autókulcs másolása (majorosplaza.hu)
          </h4>
          <p className="text-12 lg:text-14 font-normal">
            Kulcsmásolás 10% kedvezmény <br />
            Autóalkatrész 10% kedvezmény <br />
            Elemcsere 10% kedvezmény <br />
            Ajándék 10% kedvezmény <br />
          </p>
          <h4 className="text-12 lg:text-14 font-bold mt-6">
            Ittali Bisztró Ittali Pizza Vác
          </h4>
          <p className="text-12 lg:text-14 font-normal">
            10% kedvezmény a teljes fogyasztásra minden hétköznap (hétfőtől péntekig, kivéve ünnepnapok)
          </p>
          <h4 className="text-12 lg:text-14 font-bold mt-6">
            Mesi Clean Autókozmetika
          </h4>
          <p className="text-12 lg:text-14 font-normal">
            10% kedvezmény külső-belső kozmetikára <br/>
            Vác, Avar utca Garázssor <br/>
            +36 30 680 1545
          </p>
          <h4 className="text-12 lg:text-14 font-bold mt-6">
            Váci Futó utánfutó kölcsönzés
          </h4>
          <p className="text-12 lg:text-14 font-normal">
            10% kedvezmény minden utánfutó kölcsönzésre
          </p>
          <p className="font-medium text-10 lg:text-11 mt-10">
            * A Rajt Autósiskola nem vállal felelősséget a jelen együttműködés alapján bármely az elfogadóhely által nyújtott szolgáltatással kapcsolatos bármilyen körülményért.
          </p>
        </div>
      </div>
    </section>
  );
}

export default PartnerCard;
