//React

import React from "react";
//Components

import { NavLink } from "react-router-dom";

function Prices() {

  React.useEffect(() => {
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: "/araink",
        title: "Árak"
      }
    });

    document.title = process.env.REACT_APP_NAME + ' - Árak';
  }, []);

  return (
    <section id="prices" className="max-w-viewport w-full">
      <h2 className="font-medium text-center text-20 2xl:text-40">Áraink</h2>
      <div className="flex flex-col lg:flex-row 2xl:gap-8 gap-4 lg:items-center mt-8 pl-0 pr-0 lg:pr-6 lg:pl-6 2xl:pl-0 2xl:pr-0">
        <div className="secondary rounded-none lg:rounded-1.875 bg-card pl-8 pr-8 pb-10 pt-10 p 2xl:pl-10 2xl:pr-10 2xl:pb-12 2xl:pt-12">
          <h3 className="text-25 2xl:text-43">Alap</h3>
          <p className="text-12 2xl:text-17 font-medium">Kresz tanfolyam: 50.000Ft</p>
          <p className="text-12 2xl:text-17 font-medium">Kresz vizsgadíj: 4 600 Ft</p>
          <p className="text-12 2xl:text-17 font-medium">Forgalmi vizsgadíj: 11 000 Ft</p>
          <p className="text-12 2xl:text-17 font-medium">Gyakorlati képzés: 10 500 Ft</p>
          <div className="line"></div>
          <div className="flex flex-row gap-6 mb-2">
            <p className="text-12 2xl:text-17 font-medium">Végösszeg:</p>
            <div className="flex flex-row items-baseline gap-2">
              <h2 className="font-bold text-20 2xl:text-40">380.600</h2>
              <h4 className="font-extrabold text-17 2xl:text-25">Ft</h4>
            </div>
          </div>
          <ul>
            <li className="flex flex-row gap-2 items-center mt-2">
              <img src="/assets/logos/small-check-logo.png" alt="dot-img" />
              <p className="text-12 2xl:text-14 text-secondary">Saját időbeosztásodhoz igazodó, akár hétvégi tanulás és vezetési alkalmak.</p>
            </li>
            <li className="flex flex-row gap-2 items-center mt-2">
              <img src="/assets/logos/small-check-logo.png" alt="dot-img" />
              <p className="text-12 2xl:text-14 text-secondary">Kényelmes, nyugodt egyéni haladási ütemben veheted fel az órákat.</p>
            </li>
            <li className="flex flex-row gap-2 items-center mt-2">
              <img src="/assets/logos/small-check-logo.png" alt="dot-img" />
              <p className="text-12 2xl:text-14 text-secondary font-bold">A biztos tudás birtokában vághatsz bele a vizsgákba.</p>
            </li>
          </ul>
          <NavLink to="/kapcsolat" className="end-icon bg-black text-white mt-8 pl-5 pr-5 pt-2 pb-2 w-36 hover:shadow-xl hover:bg-white hover:text-black">
            <p className="font-medium text-10 2xl:text-15">Kezdés</p>
            <img src="/assets/logos/primary-chevron-right.png" alt="chevron-right" className="w-auto h-2 2xl:h-3" />
          </NavLink>
        </div>
        <div className="bg-primary pl-8 pr-8 pb-10 pt-10 p 2xl:pl-10 2xl:pr-10 2xl:pb-12 2xl:pt-12 rounded-none lg:rounded-1.875">
          <h3 className="text-25 2xl:text-43">Intenzív</h3>
          <p className="text-15 2xl:text-20 font-medium">Kresz tanfolyam: 50.000Ft</p>
          <p className="text-15 2xl:text-20 font-medium">Kresz vizsgadíj: 4 600 Ft</p>
          <p className="text-15 2xl:text-20 font-medium">Forgalmi vizsgadíj: 11 000 Ft</p>
          <p className="text-15 2xl:text-20 font-medium mb-8">Gyakorlati képzés: 12 000 Ft</p>
          <div className="line"></div>
          <div className="flex flex-row gap-6 mb-4 mt-8">
            <p className="text-15 2xl:text-20 font-medium">Végösszeg:</p>
            <div className="flex flex-row items-baseline gap-2">
              <h2 className="font-bold text-20 2xl:text-40">425.600</h2>
              <h4 className="font-extrabold text-17 2xl:text-25">Ft</h4>
            </div>
          </div>
          <ul>
            <li className="flex flex-row gap-2 items-center mt-2">
              <img src="/assets/logos/small-check-logo.png" alt="dot-img" />
              <p className="text-12 2xl:text-16 ">2 héten belül autóra kerülhetsz.</p>
            </li>
            <li className="flex flex-row gap-2 items-center mt-2">
              <img src="/assets/logos/small-check-logo.png" alt="dot-img" />
              <p className="text-12 2xl:text-16 ">Hetente akár 4 vezetési gyakorlati tanórán is részt vehetsz.</p>
            </li>
            <li className="flex flex-row gap-2 items-center mt-2">
              <img src="/assets/logos/small-check-logo.png" alt="dot-img" />
              <p className="text-12 2xl:text-16 ">2 hónap alatt levezetheted a kötelező forgalmi óraszámot.</p>
            </li>
            <li className="flex flex-row gap-2 items-center mt-2">
              <img src="/assets/logos/small-check-logo.png" alt="dot-img" />
              <p className="text-12 2xl:text-16 font-bold ">Villámgyorsan jogosítványt szerezhetsz.</p>
            </li>
          </ul>
          <NavLink to="/kapcsolat" className="end-icon bg-black text-white mt-8 pl-5 pr-5 pt-2 pb-2 w-36 hover:shadow-xl hover:bg-white hover:text-black">
            <p className="font-medium text-10 2xl:text-15">Kezdés</p>
            <img src="/assets/logos/primary-chevron-right.png" alt="chevron-right" className="w-auto h-2 2xl:h-3" />
          </NavLink>
        </div>
        <div className="secondary rounded-none lg:rounded-1.875 bg-card pl-8 pr-8 pb-10 pt-10 p 2xl:pl-10 2xl:pr-10 2xl:pb-12 2xl:pt-12">
          <h3 className="text-25 2xl:text-43">Automata</h3>
          <p className="text-12 2xl:text-17 font-medium">Kresz tanfolyam: 50.000Ft</p>
          <p className="text-12 2xl:text-17 font-medium">Kresz vizsgadíj: 4 600 Ft</p>
          <p className="text-12 2xl:text-17 font-medium">Forgalmi vizsgadíj: 11 000 Ft</p>
          <p className="text-12 2xl:text-17 font-medium">Gyakorlati képzés: 13 000 Ft</p>
          <div className="line"></div>
          <div className="flex flex-row gap-6 mb-2">
            <p className="text-12 2xl:text-17 font-medium">Végösszeg:</p>
            <div className="flex flex-row items-baseline gap-2">
              <h2 className="font-bold text-20 2xl:text-40">455.600</h2>
              <h4 className="font-extrabold text-17 2xl:text-25">Ft</h4>
            </div>
          </div>
          <ul>
            <li className="flex flex-row gap-2 items-center mt-2">
              <img src="/assets/logos/small-check-logo.png" alt="dot-img" />
              <p className="text-12 2xl:text-14 text-secondary">Prémium gépjárművel teljesítheted a kötelező óraszámot.</p>
            </li>
            <li className="flex flex-row gap-2 items-center mt-2">
              <img src="/assets/logos/small-check-logo.png" alt="dot-img" />
              <p className="text-12 2xl:text-14 text-secondary">Az automata váltós autók modern elektronikai rendszereinek kezelését is megtanulod.</p>
            </li>
            <li className="flex flex-row gap-2 items-center mt-2">
              <img src="/assets/logos/small-check-logo.png" alt="dot-img" />
              <p className="text-12 2xl:text-14 text-secondary font-bold">Könnyebben, gyorsabb ütemben tanulhatsz meg vezetni.</p>
            </li>
          </ul>
          <NavLink to="/kapcsolat" className="end-icon bg-black text-white mt-8 pl-5 pr-5 pt-2 pb-2 w-36 hover:shadow-xl hover:bg-white hover:text-black">
            <p className="font-medium text-10 2xl:text-15">Kezdés</p>
            <img src="/assets/logos/primary-chevron-right.png" alt="chevron-right" className="w-auto h-2 2xl:h-3" />
          </NavLink>
        </div>
      </div>
      <div className="pr-4 pl-4 2xl:pr-0 2xl:pl-0">
        <p className="mr-auto mt-8 text-10 2xl:text-14">* Áraink forintban értendők és tartalmazzák az ÁFÁ-t.</p>
        <p className="mr-auto text-10 2xl:text-14">** Angol/német nyelvű KRESZ tanfolyam <span className=" text-red-500">50.000 Ft</span> felár, gyakorlati oktatás <span className=" text-red-500">4 500 Ft</span> felár.</p>
        <p className="text-10 2xl:text-14 mt-4 lg:mt-8">A végösszegek esetében a gyakorlati oktatás díja a kötelező gyakorlati óraszám alapján kalkulált összeg. A szükséges plusz órák plusz költséggel járnak, melynek pontos díja a szükséges órák száma és a fenti táblázatban jelölt óradíjak alapján kalkulálhatók.</p>
        <p className="text-10 2xl:text-14 mt-2">
          Az árak az elsősegély tanfolyamot és a vizsga árát nem tartalmazzák.
        </p>
      </div>
    </section>
  );
}

export default Prices;
